<template>
  <div class="flex w-full bg-img vx-row no-gutter justify-center items-center register-page">
    <div class="register-title">
      <div class="vx-col flex w-full bg-img vx-row items-center justify-center">
      <span class="text-xl text-primary text-center font-extrabold wro-hr-title">
        Register as a user for WRO-V
      </span>
      </div>

      <vs-button
        class="mb-4 rounded-full md:w-auto"
        color="primary" @click="$router.push('/memberCountries')">
        Your country portal
      </vs-button>
    </div>

    <div class="vx-col flex w-full bg-img vx-row items-center justify-center">
      <span class="text-xl text-primary text-center font-extrabold primary-border-bottom">
        Register as national organizer
      </span>
    </div>

    <div class="vx-col w-full sm:m-0 m-4">
      <div slot="no-body" class="full-page-bg-color">
        <!-- starts notes div -->
        <div class="notes">
          <p>Notes : To register as National Organizer (NO), you need to be a registered member in WRO association
            first. WRO association members country representatives here. <a
              href="https://wro-association.org/association/member-countries">https://wro-association.org/association/member-countries</a>
          </p>
        </div>
        <!-- end notes div -->
        <div class="vx-row no-gutter">
          <div class="vx-col w-full mx-auto self-center d-theme-dark-bg">
            <div class="register-tabs-container">
              <div class="vx-card__title"></div>
              <RegisterJWT></RegisterJWT>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterJWT from "./RegisterJWT.vue";

export default {
  components: {
    RegisterJWT
  }
};
</script>

<style lang="scss">
.notes p {
  padding: 15px 0px;
  color: #AAAAAA;
}

.register-tabs-container {
  min-height: 577px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
